<template>
  <div>
    <el-select
      size="medium"
      v-model="search"
      filterable
      remote
      reserve-keyword
      placeholder="Поиск товара"
      :remote-method="searchProducts"
      :loading="loading"
      @change="selectProduct"
    >
      <el-option
        v-for="item in products"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { getProducts } from "@/api/product";

export default {
  name: "SearchCatalogProducts",
  data() {
    return {
      search: null,
      loading: false,
      products: [],
    };
  },
  methods: {
    async searchProducts(query) {
      if (query && query.length > 2) {
        this.loading = true;
        const { products } = await getProducts({ query: query });
        this.loading = false;
        this.products = products;
      }
    },
    selectProduct(productId) {
      const product = this.products.find((p) => p.id === productId);
      const { id, name } = product;
      this.$emit("select:product", { id, name });
      this.search = null;
    },
  },
};
</script>

<style scoped></style>
